import React from "react";
import Breadcrumbs from "../components/global/breadcrumbs";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";

// CSS
import "../styles.css"

const TermsConditions = () => {
   return (
      <React.Fragment>
         <SEO
            title="Terms & Conditions"
            slug="terms-conditions"
            metaDescription="Find past and present terms & condition documents for your policy(s)."
            image=""
            titleTemplate="%s - Appliance Sure"
         />

         <Navigation/>

         <div className="content-hero-container">
            <div className="content-hero-content-container">
               <h1>Terms & conditions</h1>
               
               <p>From time to time, and with the launch of new product offerings, we change our conditions to suit. On this page you can find past and present terms & conditions.</p>
            </div>
         </div>

         <div className="standard-container">
            <div className="content-container narrow-width">
               <Breadcrumbs
                  location="terms-conditions"
               />

               <p>Below is a list of past and present terms & conditions for our various plans that we have had available. If you cannot find the exact name of your plan, then it is likely that your plan falls under our standard terms & conditions. Please note that your terms & conditions must be read in conjunction with your cover sheet.</p>
            </div>
         </div>

         <div className="standard-container" style={{padding: 0}}>
            <div className="about-us-numbers-outer-container">
               <div className="about-us-numbers-inner-container narrow-width">
                  <h2>Current documents</h2>

                  <br/>
                  <br/>
                  
                  <div className="document-cards-container">
                     <a className="about-us-numbers-card link" href="/documents/standard_terms_conditions_oct2022.pdf" target="_blank">
                        <span>
                           <h3><i class="fa-regular fa-file-pdf"></i></h3>
                           <p>Standard T&Cs</p>
                        </span>

                        <p className="about-us-numbers-date">Last updated: 26th October 2022</p>
                     </a>

                     <a className="about-us-numbers-card link" href="/terms-conditions/washing-machine-classifications">
                        <span>
                           <h3><i class="fa-solid fa-link"></i></h3>
                           <p>Washing machine classifications</p>
                        </span>

                        <p className="about-us-numbers-date">Last updated: 28th October 2022</p>
                     </a>
                  </div>

                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  <h2>Past documents</h2>

                  <br/>
                  <br/>

                  <p>There are currently no documents to display</p>


               </div>
            </div>
         </div>
         <Footer/>
      </React.Fragment>
   )
}

export default TermsConditions